<template>
	<div>
		<!-- タイトル -->
		<PageTitle title-name="生徒一覧" />
		<VmBox>
			<template #header>
				<v-col class="csv-upload-area">
					<CsvUploadArea type="STUDENT" @reload="loadTableData" />
				</v-col>
			</template>
			<template #content>
				<div class="pagination" v-if="meta && meta.totalPages">
					<button v-if="!isFirstPage" @click="firstPage">
						<v-icon small> mdi-page-first </v-icon>
					</button>
					<button v-if="!isFirstPage" @click="prevPage">
						<v-icon small> mdi-chevron-left </v-icon>
					</button>
					<div>{{ currentPage }} / {{ meta.totalPages }} page</div>
					<button v-if="!isLastPage" @click="nextPage">
						<v-icon small> mdi-chevron-right </v-icon>
					</button>
					<button v-if="!isLastPage" @click="lastPage">
						<v-icon small> mdi-page-last </v-icon>
					</button>
				</div>
				<v-data-table
					v-model="selected"
					:headers="headers"
					:items="studentItems"
					multi-sort
					class="elevation-1 overflow-y-auto"
					disable-pagination
					no-data-text="生徒がいません"
					hide-default-footer
					v-if="showTable"
					:loading="studentsLoading"
					fixed-header
					:height="studentArray.length > 15 ? 48 * 15 - 1 : undefined"
				>
					<template v-slot:top>
						<v-dialog v-model="showAddDialog" max-width="500px">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openAddDialog">
									新規生徒
								</v-btn>
							</template>
							<AdminAddFormCard2
								@closeAction="closeAdd"
								@addAction="add"
								:editedItem="editedItem"
								formTitle="生徒追加"
								@changeFile="changeFile($event)"
								:loading="studentsLoading"
							/>
						</v-dialog>
						<v-dialog v-model="showEditDialog" max-width="500px">
							<AdminAddFormCard2
								@closeAction="closeEditDialog"
								@addAction="save"
								:editedItem="filteredEditItem"
								formTitle="生徒情報編集"
								buttonTitle="edit"
								@changeFile="changeFile($event)"
								:loading="studentsLoading"
							/>
						</v-dialog>
						<v-dialog v-model="showDeleteDialog" max-width="500px">
							<AdminDeleteFormCard
								@closeDeleteAction="closeDelete"
								@deleteItemConfirmAction="deleteItemConfirm"
								formTitle="この生徒情報を削除しますか？"
							/>
						</v-dialog>
						<v-dialog v-model="showTicketDialog" max-width="500px">
							<AdminAddTicketCard
								:studentId="selectedStudentId"
								:studentName="selectedStudentName"
								@closeAction="closeTicketDialog"
								@reloadTable="loadTableData"
							/>
						</v-dialog>
						<v-text-field
							v-model="searchQuery"
							label="検索ワード"
							class="filter-item"
							@keyup.enter="searchWithKeyword"
						></v-text-field>
					</template>
					<!-- 文字数制限をかけるため以下のような実装をする -->
					<template v-for="(headerItem, key, index) in headers" v-slot:[`item.${headerItem.value}`]="{ item }">
						<p :style="{ margin: '0' }" :key="index">
							{{ $limitedText30(item[headerItem.value]) }}
						</p>
					</template>
					<template v-slot:[`item.edit`]="{ item }">
						<v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
						<v-icon small @click="openTicketDialog(item)"> mdi-ticket </v-icon>
					</template>
					<template v-slot:[`item.delete`]="{ item }">
						<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
					</template>
				</v-data-table>
			</template>
		</VmBox>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AdminEditingFormCard from '../Organisms/AdminEditingFormCard'
import AdminDeleteFormCard from '../Organisms/AdminDeleteFormCard'
import AdminAddFormCard from '../Organisms/AdminAddFormCard'
import AdminAddTicketCard from '../Organisms/AdminAddTicketCard'
import CsvUploadArea from '../Molecules/CsvUploadArea.vue'
import PageTitle from '../Atoms/PageTitle'
import VmBox from '../Atoms/VmBox'
import AdminAddFormCard2 from '../Organisms/AdminAddFormCard2'

export default {
	name: 'AdminStudentList',
	components: {
		AdminEditingFormCard,
		AdminDeleteFormCard,
		AdminAddFormCard,
		AdminAddTicketCard,
		CsvUploadArea,
		PageTitle,
		VmBox,
		AdminAddFormCard2
	},
	data: () => ({
		selected: [],
		studentArray: [],
		isShowOnlyDone: false,
		isShowFromToday: false,
		searchQuery: '',
		showEditDialog: false,
		showAddDialog: false,
		showDeleteDialog: false,
		showTicketDialog: false,
		selectedStudentId: 0,
		selectedStudentName: '',
		editedItem: {
			studentId: {
				value: '',
				type: 'display',
				required: false,
				label: 'id'
			},
			name: {
				value: '',
				type: 'textField',
				required: true,
				label: '名前',
				default: ''
			},
			email: {
				value: '',
				type: 'textField',
				required: true,
				label: 'email',
				default: ''
			},
			password: {
				value: '',
				type: 'textField',
				required: false,
				label: 'password',
				default: ''
			},
			numberOfTicket: {
				value: 0,
				type: 'number',
				required: true,
				label: 'チケット数',
				default: 0
			},
			lessonCourseIds: {
				value: '',
				type: 'textField',
				required: true,
				label: '受講コース',
				default: ''
			},
			birthDay: {
				value: null,
				type: 'date',
				required: true,
				label: '生年月日',
				default: ''
			},
			sex: {
				value: 0,
				type: 'number',
				required: false,
				label: '性別'
			},
			country: {
				value: '',
				type: 'textField',
				required: false,
				label: '国',
				default: ''
			},
			studentIdNum: {
				value: 0,
				type: 'number',
				required: false,
				label: '生徒番号',
				default: 0
			},
			category: {
				value: '',
				type: 'textField',
				required: false,
				label: 'カテゴリー',
				default: ''
			},
			level: {
				value: '',
				type: 'textField',
				required: false,
				label: 'レベル',
				default: ''
			},
			correctionFrequency: {
				value: '',
				type: 'textField',
				required: false,
				label: '訂正の頻度',
				default: ''
			},
			monthlyFeedback: {
				value: false,
				type: 'boolean',
				required: false,
				label: '月末フィードバックの有無',
				default: false
			},
			otherItem: {
				value: '',
				type: 'textField',
				required: false,
				label: 'その他',
				default: ''
			},
			hopeInLesson: {
				value: '',
				type: 'textField',
				required: false,
				label: 'レッスンの希望',
				default: ''
			},
			learningGoal: {
				value: '',
				type: 'textField',
				required: false,
				label: 'レッスンの目標',
				default: ''
			},
			school: {
				value: '',
				type: 'textField',
				required: false,
				label: '在籍学校',
				default: ''
			},
			major: {
				value: '',
				type: 'textField',
				required: false,
				label: '専攻名',
				default: ''
			},
			stripeId: {
				value: '',
				type: 'textField',
				required: false,
				label: 'Stripe顧客ID',
				default: ''
			},
			isRemindedConv2Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: '会話リマインド2時間前',
				default: false
			},
			isRemindedConv24Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: '会話リマインド24時間前',
				default: false
			},
			isRemindedConv72Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: '会話リマインド72時間前',
				default: false
			},
			isRemindedPriv2Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: 'プライベートリマインド2時間前',
				default: false
			},
			isRemindedPriv24Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: 'プライベートリマインド24時間前',
				default: false
			},
			isRemindedPriv72Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: 'プライベートリマインド72時間前',
				default: false
			},
			isAccountSuspended: {
				value: false,
				type: 'boolean',
				required: false,
				label: 'アカウント停止',
				default: false
			},
			isTestAccount: {
				value: false,
				type: 'boolean',
				required: false,
				label: 'テストアカウント',
				default: false
			},
			image: {
				value: '',
				type: 'file',
				required: false,
				label: '画像'
			}
		},
		showTable: true,
		currentPage: 1,
		studentsPerPage: 50
	}),
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			students: 'adminStudent/students',
			studentsLoading: 'adminStudent/loading',
			meta: 'adminStudent/meta'
		}),
		headers() {
			return [
				{ text: 'actions', value: 'edit', sortable: false, align: 'start' },
				// { text: '', value: 'ticket', sortable: false },
				{
					text: 'studentId',
					value: 'studentId'
				},
				{ text: '名前', value: 'name' },
				{ text: 'email', value: 'email' },
				{ text: 'チケット数（旧）', value: 'numberOfTicket' },
				{ text: 'チケット数（新）', value: 'ticketCount' },
				{ text: '受講コース', value: 'lessonCourseIds' },
				{ text: '生年月日', value: 'birthDay' },
				{ text: '性別', value: 'sex' },
				{ text: '国', value: 'country' },
				{ text: '生徒番号', value: 'studentIdNum' },
				{ text: 'カテゴリー', value: 'category' },
				{ text: 'レベル', value: 'level' },
				{ text: '訂正の頻度', value: 'correctionFrequency' },
				{ text: '月末フィードバックの有無', value: 'monthlyFeedback' },
				{ text: 'その他', value: 'otherItem' },
				{ text: 'レッスンの希望', value: 'hopeInLesson' },
				{ text: 'レッスンの目標', value: 'learningGoal' },
				{ text: '在籍学校', value: 'school' },
				{ text: '専攻名', value: 'major' },
				{ text: 'Stripe顧客ID', value: 'stripeId' },
				{ text: '会話リマインド2時間前', value: 'isRemindedConv2Hours' },
				{ text: '会話リマインド24時間前', value: 'isRemindedConv24Hours' },
				{ text: '会話リマインド72時間前', value: 'isRemindedConv72Hours' },
				{ text: 'プライベートリマインド2時間前', value: 'isRemindedPriv2Hours' },
				{ text: 'プライベートリマインド24時間前', value: 'isRemindedPriv24Hours' },
				{ text: 'プライベートリマインド72時間前', value: 'isRemindedPriv72Hours' },
				{ text: '最終更新日', value: 'updatedAt' },
				{ text: '最終ログイン日', value: 'lastLoginAt' },
				{ text: 'アカウント停止', value: 'isAccountSuspended' },
				{ text: 'テストアカウント', value: 'isTestAccount' },
				{ text: '', value: 'delete', sortable: false }
			]
		},
		filteredEditItem() {
			// this.editedItemからpasswordを除外した新しいオブジェクトを返す
			const { password, ...filteredItem } = this.editedItem
			return filteredItem
		},
		studentItems() {
			if (this.students === null || this.students.length === 0) return []

			return this.students.map((student) => {
				return {
					studentId: student.id,
					studentIdNum: student.studentIdNum,
					name: student.name,
					email: student.email,
					lessonCourseIds: student.lessonCourses.map((lessonCourse) => lessonCourse.id).join(','),
					birthDay: student.birthDay ? this.$moment(student.birthDay).format('YYYY-MM-DD') : '',
					sex: student.sex,
					country: student.country,
					numberOfTicket: student.numberOfTicket,
					ticketCount: student.ticketCount,
					category: student.category,
					level: student.level,
					correctionFrequency: student.correctionFrequency,
					monthlyFeedback: student.monthlyFeedback,
					otherItem: student.otherItem,
					hopeInLesson: student.hopeInLesson,
					learningGoal: student.learningGoal,
					school: student.school,
					major: student.major,
					stripeId: student.stripeId,
					isRemindedConv2Hours: Boolean(student.isRemindedConv2Hours),
					isRemindedConv24Hours: Boolean(student.isRemindedConv24Hours),
					isRemindedConv72Hours: Boolean(student.isRemindedConv72Hours),
					isRemindedPriv2Hours: Boolean(student.isRemindedPriv2Hours),
					isRemindedPriv24Hours: Boolean(student.isRemindedPriv24Hours),
					isRemindedPriv72Hours: Boolean(student.isRemindedPriv72Hours),
					isAccountSuspended: student.isAccountSuspended,
					isTestAccount: student.isTestAccount,
					updatedAt: student.updatedAt ? this.$moment(student.updatedAt).format('YYYY-MM-DD') : '-',
					lastLoginAt: student.lastLoginAt ? this.$moment(student.lastLoginAt).format('YYYY-MM-DD HH:mm') : '-'
				}
			})
		},
		isFirstPage() {
			return this.currentPage === 1
		},
		isLastPage() {
			return this.currentPage === this.meta.totalPages
		}
	},
	headerValues() {
		let headerValues = []
		this.headers.forEach((headerItem) => {
			headerValues.push(headerItem)
		})
		return headerValues
	},
	props: [],
	created() {
		this.loadTableData()
	},
	mounted() {},
	methods: {
		...mapActions({
			fetchStudents: 'adminStudent/fetchStudents'
		}),
		loadTableData() {
			this.fetchStudents({
				accessToken: this.userInfo.accessToken,
				page: this.currentPage,
				searchQuery: this.searchQuery
			})
		},
		editItem(item) {
			this.selectedStudentId = item.studentId
			this.mapItemToEditedItem({ item, isEdit: true })
			this.showEditDialog = true
		},
		deleteItem(item) {
			this.selectedStudentId = item.studentId
			this.mapItemToEditedItem({ item, isEdit: false })
			this.showDeleteDialog = true
		},
		openTicketDialog(item) {
			this.showTicketDialog = true
			this.selectedStudentName = item.name
			this.selectedStudentId = item.studentId
		},
		addStudent(item) {
			this.mapItemToEditedItem({ item, isEdit: false })
			this.showAddDialog = true
		},
		deleteItemConfirm() {
			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}

			this.axios
				.delete(`/api/students/${this.selectedStudentId}`, header)
				.then(() => {
					this.closeDelete()
					this.loadTableData()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		closeEditDialog() {
			this.showEditDialog = false
		},
		closeAdd() {
			this.showAddDialog = false
		},
		closeDelete() {
			this.showDeleteDialog = false
		},
		closeTicketDialog() {
			this.showTicketDialog = false
		},
		save() {
			let formData = new FormData()

			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key)) {
					formData.append(key, this.editedItem[key].value)
				}
			}
			formData.append('image', this.uploadedImage)

			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`,
					'Content-Type': 'multipart/form-data'
				}
			}

			this.axios
				.put(`/api/students/${this.selectedStudentId}`, formData, header)
				.then((response) => {
					console.log('response.data', response.data)
					this.closeEditDialog()
					this.loadTableData()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		add() {
			let formData = new FormData()

			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key)) {
					formData.append(key, this.editedItem[key].value)
				}
			}
			formData.append('image', this.uploadedImage)

			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`,
					'Content-Type': 'multipart/form-data'
				}
			}

			this.axios
				.post(`/api/students`, formData, header)
				.then(() => {
					this.closeAdd()
					this.loadTableData()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		resetEditedForm() {
			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key)) {
					this.editedItem[key].value = this.editedItem[key].type === 'number' ? 0 : ''
				}
			}
		},
		mapItemToEditedItem({ item, isEdit }) {
			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key) && item.hasOwnProperty(key)) {
					this.editedItem[key].value = item[key]
				}
			}
		},
		changeFile(elem) {
			this.uploadedImage = ''
			console.log('elem', elem)
			const files = elem.target.files || elem.dataTransfer.files
			this.uploadedImage = files[0]
		},
		openAddDialog() {
			this.resetEditedForm()
			this.showAddDialog = true
		},
		prevPage() {
			if (this.currentPage > 1) {
				this.currentPage--
				this.loadTableData()
			}
		},
		nextPage() {
			if (this.currentPage < this.meta.totalPages) {
				this.currentPage++
				this.loadTableData()
			}
		},
		firstPage() {
			this.currentPage = 1
			this.loadTableData()
		},
		lastPage() {
			this.currentPage = this.meta.totalPages
			this.loadTableData()
		},
		searchWithKeyword() {
			this.currentPage = 1
			this.loadTableData()
		}
	}
}
</script>

<style lang="scss" scoped>
v-data-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	background-color: #dcc268;
}

thead.v-data-table-header {
	background-color: #dcc268;
}

.filter-area {
	padding: 0 20px;
}
.filter-item {
	padding: 10px 5px;
}
.table-img {
	width: 100px;
	height: 100px;
	object-fit: cover;
}
.csv-upload-area {
	margin-bottom: 10px;
}
.v-data-table {
	white-space: nowrap;
}
.pagination {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}
</style>
