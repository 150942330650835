<template>
	<v-container :class="containerClass">
		<PageTitle title-name="HOME" />
		<SwitchTimeZone v-if="isShowTimeZone" @reload="reload()" />
		<div v-if="!isPC" class="internal-link-area">
			<v-row no-gutters>
				<a href="#" v-scroll-to="'#private'">
					<p class="internal-link-text">プライベート</p>
				</a>
				<a href="#" v-scroll-to="'#conversation'">
					<p class="internal-link-text">会話</p>
				</a>
				<a href="#" v-scroll-to="'#text'">
					<p class="internal-link-text">テキスト</p>
				</a>
				<a href="#" v-scroll-to="'#smallGroup'">
					<p class="internal-link-text">少人数</p>
				</a>
				<a href="#" v-scroll-to="'#videoLearning'">
					<p class="internal-link-text">動画</p>
				</a>
			</v-row>
		</div>
		<!-- レッスン情報 -->
		<ScheduleHeading class="heading" />

		<!-- 総時間 - 生徒 -->
		<v-row class="lesson-data-area" justify="start" no-gutters>
			<v-col cols="6" xs="12" sm="4" md="4" lg="2">
				<LessonData class="lesson-data" type="HOURS" :lessonData="studentLessonData" />
			</v-col>
			<v-col cols="6" xs="12" sm="4" md="4" lg="2">
				<LessonData class="lesson-data" type="TIMES" :lessonData="studentLessonData" />
			</v-col>
		</v-row>

		<!-- 応援メッセージ - 生徒 -->
		<CheerUpArea />

		<!-- レッスン予定一覧 -->
		<div>
			<SubHeading sub-heading-text="予約済みレッスンスケジュール" class="subheading" />
			<div class="lessonTableArea">
				<StudentScheduleTable v-if="lessons.length && isShowTable" :lessons="lessons" />
				<div v-if="!lessons.length">
					<p class="noLesson">予約済みレッスンがありません。ぜひレッスンを予約して、学習を継続しましょう！</p>
				</div>
			</div>
		</div>
		<ActiveButtonWithArrow
			button-title="レッスン受講履歴"
			@clickAction="toLessonHistory"
			leftIcon="mdi-clipboard-text-clock"
			class="lesson-history-button"
		/>

		<!-- 生徒画面注意事項 -->
		<div>
			<div v-if="attendConversation" class="attention">
				<p>
					※初めての先生の場合は、レッスン<span style="font-weight: bold">開始時刻24時間前までに</span
					>スカイプで先生に連絡して下さい
					<br />
					<a href="https://vollmond.online/lernen/skype/" target="_blank" rel="noopener" class="how-to-skype-link"
						>スカイプの使い方はこちら！</a
					>
				</p>
				<p>
					※マイページより予約したレッスンの「キャンセル」を受講生自身で行えるのは【レッスン開始時刻48時間前】までです。
					<br />
					レッスン開始48時間前以降のキャンセルについては、講師へ直接ご連絡ください。Vollmondの定める<a
						href="https://vollmond.online/cancel-policy/"
						target="_blank"
						rel="noopener"
						>キャンセルポリシー</a
					>に従い、キャンセル料が発生する場合がございます。
				</p>
			</div>
		</div>

		<!-- 生徒 - プライベートコース -->
		<div id="private">
			<PrivateHeading class="heading" />
			<div v-if="attendPrivate">
				<PreviouslyBookedTeachers
					v-if="previouslyBookedPrivateTeachers"
					:teachers="previouslyBookedPrivateTeachers"
					courseType="private"
					class="previously-booked-teachers"
				/>
			</div>
			<div v-if="attendPrivate">
				<ActiveButtonWithArrow button-title="プライベートコース担当講師一覧" @clickAction="toPrivateTeachers" />
			</div>
			<div v-else>
				<p class="no-latest-teacher">まだ担当講師はいません</p>
				<a :href="privateCourseSubscriptionUrl" class="" target="_blank" rel="noopener noreferrer">
					<ActiveButtonWithArrow button-title="プライベートコース申し込み" />
				</a>
			</div>
		</div>

		<!-- 生徒 - 会話コース -->
		<div id="conversation">
			<ConversationHeading class="heading" />
			<div v-if="attendConversation">
				<StudentTopTicketArea />
				<PreviouslyBookedTeachers
					v-if="previouslyBookedConversationTeachers"
					:teachers="previouslyBookedConversationTeachers"
					courseType="conversation"
					class="previously-booked-teachers"
				/>
				<ActiveButtonWithArrow button-title="会話コース担当講師一覧" @clickAction="toConversationTeachers" />
			</div>
			<div v-else>
				<p class="no-latest-teacher">まだ担当講師はいません</p>
				<a :href="conversationCourseSubscriptionUrl" class="" target="_blank" rel="noopener noreferrer">
					<ActiveButtonWithArrow button-title="会話コース申し込み" />
				</a>
			</div>
		</div>

		<!-- 生徒 - テキストコース -->
		<div id="text">
			<TextHeading class="heading" />
			<!-- テキストコース受講している人には 解約・マニュアル・講師変更を出す -->
			<div v-if="attendText" class="textArea">
				<div v-for="textCourseInfo of this.textCourseInfoArray" :key="textCourseInfo.id">
					<SubHeading sub-heading-text="選択中の頻度" class="subheading" />
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
						<p class="frequency-name-area">
							<span class="text-course-fequency">{{ computedFrequencyName(textCourseInfo.frequency) }}</span>
						</p>
					</v-col>
					<SubHeading sub-heading-text="担当講師" class="subheading" />
					<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
						<TeacherCardWithDetail :teacherInfo="textCourseInfo" showedAt="text" />
					</v-col>
				</div>
				<a href="https://vollmond.online/kontaktieren/" class="" target="_blank" rel="noopener noreferrer">
					<ActiveButtonWithArrow button-title="講師変更" />
				</a>
				<v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="3">
					<ActiveButtonWithArrow
						v-if="userInfo.isReservedStripe"
						@clickAction="toCustomerPortal"
						button-title="テキストコース解約"
					/>
				</v-col>
				<div :style="{ margin: '0 0 0 20px' }">
					<a
						href="https://vollmond.online/lektion/kundigen/"
						target="_blank"
						rel="noopener"
						:style="{ 'font-weight': 'bold', 'font-size': '0.9em' }"
						>テキストコースマニュアル</a
					>
				</div>
			</div>
			<div v-else>
				<div>
					<p class="no-latest-teacher">まだ担当講師はいません</p>
				</div>
				<a :href="textCourseSubscriptionUrl" class="" target="_blank" rel="noopener noreferrer">
					<ActiveButtonWithArrow button-title="テキストコース申し込み" />
				</a>
				<p class="apply-for-textCourse-attention">※外部サイトに接続します</p>
			</div>
		</div>
		<!-- 生徒 - 少人数コース -->
		<div id="smallGroup">
			<SmallGroupHeading class="heading" />
			<SubHeading sub-heading-text="コース情報" class="subheading" />
			<div v-if="isShowGroupLessonReservation">
				<p class="no-latest-teacher">現在{{ groupLessonReservationMonth }}月申し込み受付中です！</p>
			</div>
			<div v-else>
				<p class="no-latest-teacher">次回申し込みは20日に始まります</p>
			</div>
			<a href="https://vollmond.online/gruppenkurse/" class="" target="_blank" rel="noopener noreferrer">
				<ActiveButtonWithArrow button-title="少人数コース申し込み" />
			</a>
			<p class="apply-for-textCourse-attention">※外部サイトに接続します</p>
		</div>
		<!-- 生徒 - 動画学習コース  -->
		<div id="videoLearning">
			<VideoLearningHeading class="heading" />
			<SubHeading sub-heading-text="コース情報" class="subheading" />
			<p class="no-latest-teacher">現在申し込み受付中です！</p>
			<a href="https://vollmond.online/videokurs/" class="" target="_blank" rel="noopener noreferrer">
				<ActiveButtonWithArrow button-title="動画学習コース申し込み" />
			</a>
			<p class="apply-for-textCourse-attention">※外部サイトに接続します</p>
		</div>
	</v-container>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import ScheduleHeading from '../Molecules/ScheduleHeading'
import ConversationHeading from '../Molecules/ConversationHeading'
import PrivateHeading from '../Molecules/PrivateHeading'
import TextHeading from '../Molecules/TextHeading'
import SmallGroupHeading from '../Molecules/SmallGroupHeading'
import VideoLearningHeading from '../Molecules/VideoLearningHeading'
import StudentScheduleTable from '../Organisms/StudentScheduleTable'
import SubHeading from '../Atoms/SubHeading'
import SimpleTeacherCard from '../Organisms/SimpleTeacherCard'
import ActiveButton from '../Atoms/ActiveButton'
import ActiveButtonWithArrow from '../Atoms/ActiveButtonWithArrow'
import SwitchTimeZone from '../Atoms/SwitchTimeZone'
import userInfoTranslate from '@/data/translation/userInfoTranslate'
import TeacherCardWithDetail from '../Organisms/TeacherCardWithDetail'
import TextCourseTable from '../Organisms/TextCourseTable'
import LessonData from '../Molecules/LessonData'
import CheerUpArea from '../Molecules/CheerUpArea'
import MessageForTeacher from '../Atoms/MessageForTeacher'
import StudentTopTicketArea from '@/components/domains/conversationTicket/StudentTopTicketArea'
import { mapGetters, mapActions } from 'vuex'
import {
	PRIVATE_SUBSCRIPTION_URL,
	CONVERSATION_SUBSCRIPTION_URL,
	TEXT_SUBSCRIPTION_URL,
	PRIVATE_LESSON_COURSE_ID,
	CONVERSATION_LESSON_COURSE_ID
} from '../../const'
import PreviouslyBookedTeachers from '../Organisms/PreviouslyBookedTeachers'

export default {
	name: 'StudentTop',
	components: {
		PageTitle,
		ScheduleHeading,
		ConversationHeading,
		PrivateHeading,
		TextHeading,
		SmallGroupHeading,
		VideoLearningHeading,
		StudentScheduleTable,
		SubHeading,
		SimpleTeacherCard,
		ActiveButton,
		ActiveButtonWithArrow,
		SwitchTimeZone,
		TeacherCardWithDetail,
		TextCourseTable,
		LessonData,
		CheerUpArea,
		MessageForTeacher,
		StudentTopTicketArea,
		PreviouslyBookedTeachers
	},
	data: () => ({
		lessons: [],
		latestPrivateTeachers: [],
		regularTeachers: [],
		isShowTable: true,
		isShowTimeZone: true,
		textCourseInfoArray: [],
		studentLessonData: {},
		teacherLessonData: {}
	}),
	mounted() {},
	async created() {
		// 予約したことのある講師情報の取得（会話）
		this.getPreviouslyBookedTeachers({
			accessToken: this.userInfo.accessToken,
			requestData: {
				studentId: this.userInfo.studentId,
				lessonCourseId: CONVERSATION_LESSON_COURSE_ID
			}
		})

		// 予約したことのある講師情報の取得（プライベート）
		this.getPreviouslyBookedTeachers({
			accessToken: this.userInfo.accessToken,
			requestData: {
				studentId: this.userInfo.studentId,
				lessonCourseId: PRIVATE_LESSON_COURSE_ID
			}
		})

		// テキストコースの講師を取得
		this.fetchTextCourseInfo()

		// 生徒の授業回数、授業時間などを取得する
		this.fetchStudentLessonData()

		this.getPlannedLessons()
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			previouslyBookedPrivateTeachers: 'previouslyBookedTeachers/previouslyBookedPrivateTeachers',
			previouslyBookedConversationTeachers: 'previouslyBookedTeachers/previouslyBookedConversationTeachers'
		}),
		privateCourseSubscriptionUrl() {
			return PRIVATE_SUBSCRIPTION_URL
		},
		conversationCourseSubscriptionUrl() {
			return CONVERSATION_SUBSCRIPTION_URL
		},
		textCourseSubscriptionUrl() {
			return TEXT_SUBSCRIPTION_URL
		},
		containerClass() {
			if (this.isPC) {
				return 'wide-padding'
			} else {
				return 'narrow-padding'
			}
		},
		attendConversation() {
			if (this.userInfo.lessonCourseArray.includes('CONVERSATION')) {
				return true
			}
			return false
		},
		attendPrivate() {
			if (this.userInfo.lessonCourseArray.includes('PRIVATE')) {
				return true
			}
			return false
		},
		attendText() {
			if (this.userInfo.lessonCourseArray.includes('TEXT')) {
				return true
			}
			return false
		},
		computedFrequencyName: function () {
			return function (frequencyCode) {
				return userInfoTranslate[frequencyCode]
			}
		},
		isShowGroupLessonReservation() {
			// 少人数コースの日付を表示させるかどうかのフラグ
			// 日付表示させる時以外は「次回申し込みは20日に始まります」の文言を表示
			const currentMonth = Number(this.$moment().format('MM'))
			const currentDate = Number(this.$moment().format('DD'))

			// 基本的に20日以降は表示
			if (currentDate >= 20) {
				return true
			}

			// 例外的に1月は -1/2まで表示させる
			if (currentMonth === 1 && currentDate <= 2) {
				return true
			}

			return false
		},
		groupLessonReservationMonth() {
			const currentMonth = Number(this.$moment().format('MM'))
			const currentDate = Number(this.$moment().format('DD'))

			// 他は20日以降、次の月の分
			// 1/20 - → 2月分
			if (currentDate >= 20) {
				return this.$moment().add(1, 'M').format('M')
			}

			// 例外的に1月は -1/2までは1月分
			if (currentMonth === 1 && currentDate <= 2) {
				return 1
			}
			return 0
		}
	},
	methods: {
		...mapActions({
			resetPrivateTeachers: 'privateTeachers/resetPrivateTeachers',
			resetConversationTeachers: 'conversationTeachers/resetConversationTeachers',
			getPreviouslyBookedTeachers: 'previouslyBookedTeachers/getPreviouslyBookedTeachers'
		}),
		toConversationTeachers() {
			this.resetConversationTeachers()
			if (this.userInfo.level) {
				this.$router.push({
					name: 'ConversationTeacherList',
					query: {
						country: '',
						compatibleLevel: this.userInfo.level,
						teachingLevel: '',
						empty: ''
					}
				})
			} else {
				this.$router.push({ path: 'ConversationTeacherList' })
			}
		},
		toPrivateTeachers() {
			this.resetPrivateTeachers()
			if (this.userInfo.level) {
				this.$router.push({
					name: 'PrivateTeacherList',
					query: {
						country: '',
						compatibleLevel: this.userInfo.level,
						teachingLevel: '',
						empty: ''
					}
				})
			} else {
				this.$router.push({ path: 'PrivateTeacherList' })
			}
		},
		// 生徒が「会話コースの予約スケジュール」に遷移
		toConversationSchedule(latestConversationTeacher) {
			const teacherId = latestConversationTeacher.id
			this.$router.push({
				path: 'conversationTeacherSchedule',
				query: { teacherId: teacherId }
			})
		},
		// 生徒が「プライベートコースの予約スケジュール」に遷移
		toPrivateSchedule(latestConversationTeacher) {
			const teacherId = latestConversationTeacher.id
			this.$router.push({
				path: 'privateTeacherSchedule',
				query: { teacherId: teacherId }
			})
		},
		toLessonHistory() {
			this.$router.push('lessonHistory')
		},
		getPlannedLessons() {
			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}

			this.axios
				.get(`/api/lessons/planned?studentId=${this.userInfo.studentId}`, header)
				.then((response) => {
					this.lessons = response.data.lessons
				})
				.catch((error) => {
					console.log(error)
				})
		},
		fetchTextCourseInfo() {
			return new Promise((resolve) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}
				this.axios
					.get(`/api/textCourses?studentId=${this.userInfo.studentId}`, header)
					.then((response) => {
						this.textCourseInfoArray = response.data.textCourses

						// teacherCardに情報を伝えていく時にincludeしたままの状態だとうまく伝わらないので、無理矢理こんな実装に…
						for (let textCourseInfo of this.textCourseInfoArray) {
							textCourseInfo.teacherName = textCourseInfo['Teacher.teacherName']
							textCourseInfo.country = textCourseInfo['Teacher.country']
							textCourseInfo.catchPhrase = textCourseInfo['Teacher.catchPhrase']
							textCourseInfo.category = textCourseInfo['Teacher.category']
							textCourseInfo.teachingLevel = textCourseInfo['Teacher.teachingLevel']
							textCourseInfo.imageUrl = textCourseInfo['Teacher.imageUrl']
							textCourseInfo.selfIntroduction = textCourseInfo['Teacher.selfIntroduction']
							textCourseInfo.specialities = textCourseInfo['Teacher.specialities']
							textCourseInfo.hobbies = textCourseInfo['Teacher.hobbies']
						}

						resolve()
					})
					.catch((error) => {
						console.log(error)
					})
			})
		},
		// 生徒のレッスンデータ（総受講時間・総受講回数）を取得
		fetchStudentLessonData() {
			return new Promise((resolve) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}
				this.axios
					.get(`/api/lessons/data?studentId=${this.userInfo.studentId}`, header)
					.then((response) => {
						this.studentLessonData = response.data.lessonData
						resolve()
					})
					.catch((error) => {
						console.log(error)
					})
			})
		},
		reload() {
			this.reloadTable()
		},
		async reloadTable() {
			this.isShowTable = false
			await this.$nextTick()
			this.isShowTable = true
		},
		toCustomerPortal() {
			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`,
					'Content-Type': 'multipart/form-data'
				}
			}

			this.axios
				.get(`/api/stripes/customerPortal/${this.userInfo.studentId}`, header)
				.then((response) => {
					window.open(response.data.customerPortalUrl)
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		}
	},
	head: {
		title: {
			inner: 'home'
		}
	}
}
</script>

<style lang="scss" scoped>
p {
	white-space: pre-line;
}
a {
	text-decoration: none;
}
.teacher-card {
	margin-bottom: 30px;
}
.attention {
	font-size: 0.9em;
	margin: 10px;
	white-space: pre-line;
	color: #6e6b6b;
}
.how-to-skype-link {
	text-decoration: none;
}
.frequency-name-area {
	margin: 0 0 0 30px;
}
.text-course-fequency {
	color: #dcc268;
	font-size: 3em;
	font-weight: bold;
	padding: 20px 20px 20px 0;
}
.no-latest-teacher {
	color: #dcc268;
	font-weight: bold;
	font-size: 0.9em;
	margin: 30px 0 30px 20px;
}
.lessonTableArea {
	margin-top: 20px;
}
.noLesson {
	color: #dcc268;
	font-weight: bold;
	margin: 0;
}
.previously-booked-teachers {
	margin-bottom: 20px;
}
.lesson-history-button {
	margin: 20px 0 20px 0;
}
.heading {
	margin: 50px 0 0 0;
}
.subheading {
	margin: 20px 0 0 0;
}
.apply-for-textCourse-attention {
	margin-top: 10px;
	font-size: 0.8em;
}
.internal-link-area {
	margin: 20px 10px;
}
.internal-link-text {
	text-align: center;
	margin: 0 8px 8px 0;
	font-size: 0.8em;
	font-weight: bold;
	background-color: #cfd2d1;
	color: white;
	padding: 0 8px;
	border-radius: 4px;
}
.internal-link-text:after {
	font-family: 'Font Awesome 5 Free';
	content: '\f078';
	margin-left: 4px;
}
.lesson-data {
	margin: 0 5px 10px 5px;
}
.textArea {
	margin-top: 20px;
}
</style>
